<style lang="scss" scoped>
.prepaid {
  &__title {
    @apply text-lg font-semibold font-text my-4 mt-8;
    color: var(--color-text);
  }

  &__text {
    @apply text-base;
    color: var(--color-text);
    line-height: 1.8;
  }
}
</style>

<template>
  <section class="appointment-payment__col prepaid">
    <h3 class="prepaid__title">
      Instruções para agendamento Benefício {{ partner.name }}
    </h3>

    <p class="prepaid__text mt-6">
      Ao concluir esse agendamento você receberá um e-mail de
      confirmação da transação. Você não precisará fazer nada
      em relação ao pagamento, apenas esteja atento as
      nofificações para o início de sua consulta.
    </p>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Prepaid',

  computed: {
    ...mapGetters('partner', ['partner']),
  },
};
</script>
