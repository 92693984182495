import http from '../httpConfig';

const payment = (
  auth,
  {
    appointmentStart = null,
    doctor = null,
    doctorOffice = null,
    type = null,
    medicalSpecialty = null,
    healthPlacePartner = null,
    methodName,
    methodConst,
  },
) => {
  const headers = auth;
  const endpoint = 'user/agenda';
  const paymentMethod = methodName;
  const paymentMethodString = methodConst;

  const startReservation = () => new Promise((resolve, reject) => {
    const data = {
      agenda: {
        appointmentStart,
        doctor,
        doctorOffice,
        type,
        medicalSpecialty,
        healthPlacePartner,
        isShareMedicalHistory: 1,
        paymentMethod: paymentMethodString,
      },
    };

    http.post(endpoint, data, headers)
      .then((res) => {
        const { 'created_at': reservationStartTime, ...data } = res.data;
        resolve({ ...data, reservationStartTime });
      })
      .catch((err) => {
        if (
          'response' in err
          && 'data' in err.response
          && typeof err.response.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject(err.response.data);
      });
  });

  const completeReservation = (
    {
      agendaId = null,
      agendaUuid = null,
      creditCard = null,
      installments = 1,
      isShareMedicalHistory = true,
      voucher = null,
    } = {},
  ) => new Promise((resolve, reject) => {
    const suffix = agendaUuid ? `?uuid=${agendaUuid}` : '';
    const url = `${endpoint}/${agendaId}/checkout${suffix}`;

    const data = {
      'user_agenda_checkout': {
        isShareMedicalHistory: isShareMedicalHistory ? 1 : 0,
        installments,
      },
    };

    if (paymentMethod === 'creditCard') {
      data['user_agenda_checkout'].creditCard = creditCard;
      data['user_agenda_checkout'].installments = installments;
    }

    if (voucher) {
      data['user_agenda_checkout'].prepaidVoucher = voucher;
    }

    http.post(url, data, headers)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (
          'response' in err
          && 'data' in err.response
          && typeof err.response.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject(err.response.data);
      });
  });

  return {
    startReservation,
    completeReservation,
  };
};

export default payment;
