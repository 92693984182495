<style lang="scss" scoped>
.boleto {
  @apply flex flex-col items-start content-start justify-start;

  &-instructions {
    @apply flex flex-col items-start justify-center;
    @apply my-4;

    &__item {
      @apply flex flex-row items-center justify-start;
      @apply mb-6;
    }

    &__icon {
      @apply flex items-center justify-center;
      @apply rounded rounded-full;
      @apply mr-2 h-10 w-10;
      background: var(--button-primary-bg);

      svg {
        @apply pt-1;
        height: 2rem;
      }
    }

    &__text {
      @apply text-sm;
      color: var(--color-text);
    }
  }

  &__disclaimer {
    @apply text-sm leading-6;
    color: var(--color-text);
  }
}
</style>

<template>
  <section class="appointment-payment__col boleto">
    <ul class="boleto-instructions">
      <li class="boleto-instructions__item">
        <div class="boleto-instructions__icon">
          <icon
            name="boletoPrint"
            color="white"
          />
        </div>

        <p class="boleto-instructions__text">
          imprima o boleto e <strong>pague no banco</strong>
        </p>
      </li>

      <li class="boleto-instructions__item">
        <div class="boleto-instructions__icon">
          <icon
            name="boletoWindow"
            color="white"
          />
        </div>

        <p class="boleto-instructions__text">
          <strong>ou pague pela internet</strong> utilizando o código
          de barras do boleto
        </p>
      </li>

      <li class="boleto-instructions__item">
        <div class="boleto-instructions__icon">
          <icon
            name="boletoPay"
            color="white"
          />
        </div>

        <p class="boleto-instructions__text">
          o prazo de validade do boleto é de <strong>1 dia útil</strong>
        </p>
      </li>
    </ul>

    <p class="boleto__disclaimer">
      Ao concluir esse agendamento você receberá um link com o boleto para pagamento
      com vencimento na data de hoje e após a compensação bancária sua consulta
      será confirmada. O não pagamento implicará no cancelamento automático do
      agendamento.
    </p>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'Boleto',

  components: {
    Icon,
  },

  created() {
    this.setPayment({ installments: 1 });
  },

  methods: {
    ...mapActions('scheduling', ['setPayment']),
  },
};
</script>
