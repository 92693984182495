<template>
  <div
    v-if="hasLoaded"
    class="appointment-payment__col"
  >
    <h4 class="form__title">
      Pagamento
    </h4>

    <field
      v-if="creditCardListOptions.length > 0"
      v-model="creditCardId"
      type="select"
      label="Cartão de crédito"
      placeholder="Selecione um cartão"
      :options="creditCardListOptions"
    />

    <ui-form
      :ref="'form-payment-info'"
      id="form-payment"
      :form-data="formDataCreditCard"
      :validations.sync="$v.formDataCreditCard"
      :disabled="isFormDisabled"
      :class="{ 'mt-4': logged }"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import payment from '@/api/user/payment';
import { PAYMENT_INSTALLMENTS_NUMBER } from '@/data/constants';
import * as formDataCreditCard from '@/data/appointmentScheduling/formCreditCard';
import paymentFunctions from '@/mixins/paymentFunctions';
import Field from '@/components/ui/Field.vue';
import UiForm from '@/components/ui/Form.vue';

export default {
  name: 'CreditCard',

  mixins: [paymentFunctions],

  components: {
    Field,
    UiForm,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  validations: {
    formDataCreditCard: formDataCreditCard.validations,
  },

  data() {
    return {
      formDataCreditCard: formDataCreditCard.formData,
      creditCard: null,
      creditCardList: null,
      creditCardId: 0,
      hasLoaded: false,
    };
  },

  computed: {
    ...mapGetters('scheduling', ['appointment', 'payment', 'patient']),
    ...mapGetters('user', ['logged', 'headers']),

    creditCardListOptions() {
      if (
        Array.isArray(this.creditCardList)
        && this.creditCardList.length > 0
      ) {
        const list = this.creditCardList
          .filter((card) => card['is_valid'])
          .sort((a, b) => {
            if (a['is_default'] && !b['is_default']) {
              return -1;
            }

            if (!a['is_default'] && b['is_default']) {
              return 1;
            }

            return 0;
          });

        const newList = list.map((card) => {
          const { brand, 'last_digits': last, 'id': value } = card;
          let label = `${brand} com final ${last}`;
          label = label.charAt(0).toUpperCase() + label.slice(1);

          return {
            label,
            value,
          };
        });

        newList.push({ label: 'Cadastrar novo cartão', value: 0 });

        return newList;
      }

      return [];
    },

    isFormDisabled() {
      if (this.logged) {
        return false;
      }

      return this.disabled;
    },
  },

  watch: {
    formDataCreditCard: {
      deep: true,
      immediate: false,
      handler() {
        this.touchFormField('formDataCreditCard');
        this.setPayment({
          data: this.formDataCreditCard,
          validations: this.$v.formDataCreditCard,
        });
      },
    },

    'formDataCreditCard.installmentsNumber.value': {
      deep: false,
      immediate: false,
      handler(v) {
        this.setPayment({ installments: v });
      },
    },

    creditCardListOptions: {
      deep: true,
      immediate: true,
      handler(list) {
        if (Array.isArray(list) && list.length === 2) {
          this.creditCardId = list[0].value;
        }
      },
    },

    creditCardId: {
      deep: false,
      immediate: true,
      handler(id) {
        if (id > 0) {
          this.setPayment({ id });
          this.loadCreditCardDetails(id);
          return;
        }

        this.setPayment({ id: null });
        this.resetFormData(this.formDataCreditCard);
        this.enableForm(this.formDataCreditCard);
        this.formDataCreditCard.cvc.type = 'tel';
        this.formDataCreditCard.cardNumber.mask = ['#### #### #### ####'];
        this.formDataCreditCard.installmentsNumber.value = 1;
      },
    },
  },

  created() {
    this.formDataCreditCard.cardNumber.mask = ['#### #### #### ####'];

    this.formDataCreditCard
      .installmentsNumber
      .options = this.createPaymentOptions(this.payment.price, PAYMENT_INSTALLMENTS_NUMBER);

    this.hasLoaded = true;

    if (this.logged || this.patient.wasUnlogged) {
      this.init();
    }
  },

  mounted() {
    this.setPayment({
      data: this.formDataCreditCard,
      validations: this.$v.formDataCreditCard,
    });
  },

  destroyed() {
    this.resetFormData(this.formDataAddress);
  },

  methods: {
    ...mapActions('scheduling', ['setPayment']),

    init() {
      if (this.patient.wasUnlogged || this.appointment.id) {
        this.getCreditCardList()
          .finally(() => {
            this.hasLoaded = true;
            this.enableForm(this.formDataCreditCard);
            this.formDataCreditCard.installmentsNumber.value = 1;
          });
      }
    },

    getCreditCardList() {
      this.creditCard = payment(this.headers);
      return this.creditCard.list()
        .then((res) => {
          this.creditCardList = res;
        });
    },

    loadCreditCardDetails(id) {
      const card = this.creditCardList.find((item) => item.id === id);

      this.$nextTick(() => {
        this.formDataCreditCard.cardNumber.mask = ['#### ##SS SSSS ####'];
        this.formDataCreditCard.cardNumber.value = `${card['first_digits']}XXXXXX${card['last_digits']}`;
        this.formDataCreditCard.cardNumber.disabled = true;
        this.formDataCreditCard.cvc.type = 'password';
        this.formDataCreditCard.cvc.value = '123';
        this.formDataCreditCard.cvc.disabled = true;
        this.formDataCreditCard.expirationDate.value = card['expiration_date'];
        this.formDataCreditCard.expirationDate.disabled = true;
        this.formDataCreditCard.name.value = card['holder_name'];
        this.formDataCreditCard.name.disabled = true;
        this.formDataCreditCard.installmentsNumber.value = 1;
      });
    },
  },
};
</script>
