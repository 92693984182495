import http from '../httpConfig';

const payment = (
  partnerId,
  doctorOffice,
  startingDatetime,
  medicalSpecialtyId,
  methodName,
  methodConst,
) => {
  const endpoint = `/health_place_partner/${partnerId}/agenda`;
  const paymentMethod = methodName;
  const paymentMethodString = methodConst;

  const startReservation = () => new Promise((resolve, reject) => {
    const data = {
      'health_place_partner_agenda': {
        doctorOfficeUuid: doctorOffice,
        agendaAppointmentStart: startingDatetime,
        medicalSpecialtyId,
        paymentMethod: paymentMethodString,
      },
    };

    http.post(endpoint, data)
      .then((res) => {
        const { 'created_at': reservationStartTime, ...data } = res.data;
        resolve({ ...data, reservationStartTime });
      })
      .catch((err) => {
        if (
          typeof err === 'object'
          && 'response' in err
          && typeof err.response === 'object'
          && 'data' in err.response
          && typeof err.response.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject(err.response.data);
      });
  });

  const completeReservation = (
    {
      agendaId = null,
      personalInfo = {},
      addressInfo = {},
      paymentInfo = {},
      code = null,
      isShareMedicalHistory = true,
      voucher = null,
    } = {},
  ) => new Promise((resolve, reject) => {
    const url = `${endpoint}/${agendaId}/checkout`;

    const {
      name: userName,
      email: userEmail,
      cpf: userCpf,
      birthdate: userDateOfBirth,
      ddi: userDdiContactPhone,
      phoneNumber: userContactPhone,
      gender: userGender,
    } = personalInfo;

    const {
      street: addressStreet,
      number: addressNumber,
      district: addressDistrict,
      cep: addressCep,
      uf: ufName,
      city: cityName,
    } = addressInfo;

    const {
      name: creditCardHolderName,
      cardNumber: creditCardNumber,
      expirationDate: creditCardExpirationDate,
      cvc: creditCardCvv,
      installmentsNumber: installments,
    } = paymentInfo;

    const data = {
      'checkout_health_place_partner_agenda': {
        userName,
        userEmail,
        userCpf,
        userGender,
        userDateOfBirth,
        userDdiContactPhone,
        userContactPhone,
        addressStreet,
        addressNumber,
        addressDistrict,
        addressCep,
        ufName,
        cityName,
        isShareMedicalHistory: isShareMedicalHistory ? 1 : 0,
        installments: 1,
      },
    };

    if (paymentMethod === 'creditCard') {
      data['checkout_health_place_partner_agenda'].creditCardHolderName = creditCardHolderName;
      data['checkout_health_place_partner_agenda'].creditCardNumber = creditCardNumber;
      data['checkout_health_place_partner_agenda'].creditCardExpirationDate = creditCardExpirationDate;
      data['checkout_health_place_partner_agenda'].creditCardCvv = creditCardCvv;
      data['checkout_health_place_partner_agenda'].installments = installments;
    }

    if (code) {
      data['checkout_health_place_partner_agenda'].validityCode = code;
    }

    if (voucher) {
      data['checkout_health_place_partner_agenda'].prepaidVoucher = voucher;
    }

    // apagar
    delete data['checkout_health_place_partner_agenda'].userDdiContactPhone;

    http.put(url, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (
          typeof err === 'object'
          && 'response' in err
          && typeof err.response === 'object'
          && 'data' in err.response
          && typeof err.response.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject(err.response.data);
      });
  });

  return {
    startReservation,
    completeReservation,
  };
};

export default payment;
